import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo";
import itunesLogo from "../images/20190327_LandingPage_AppStore.png";
import googleLogo from "../images/20190327_LandingPage_PlayStore.png";
import mockup from "../images/20190327_iPhone-X-Mockup_2019_dashboard_shadow.jpg";
import mockupResponsive from "../images/20190327_iPhone-X-Mockup_2019_dashboard_mobile.jpg";

const IndexPage = () => (
  <Layout>
    <SEO 
      title="Angelwetter - Dein perfekter Angelbegleiter" 
      description="Angelwetter App: Moderner Angelplaner mit Profi-Funktionen, detaillierte Wetterdaten, 
        Mondphasen & Mondhelligkeit, Solunardaten mit Aussicht
        auf Angelerfolg, Angel- & Schonzeiten
        für Deine Region" 
      keywords={[`Angeln`, `Angelwetter`, `Solunar`]} />
    <div
      style={{
        backgroundImage: `url(${mockup})`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: '800px 566px',
        minHeight: 566,
        width: 1260,
      }}
      className="contentWrap"
    >
      <div
        style={{
          textAlign: 'center',
          display: 'none'
        }}
        className="mockupResponsive"
      >
        <img src={mockupResponsive} />
      </div>
      <div style={{ width: 540, float: 'left', paddingTop: 140 }} className="contentWrapLeft">
        <h1
          style={{
            fontWeight: 500,
            fontFamily: 'Quicksand',
            fontSize: 65,
            lineHeight: '100px'
          }}
        >Dein perfekter<br />
        Angelbegleiter</h1>
        <div
          style={{
            marginTop: 50
          }}
        >
        Jetzt gratis erhältlich im App Store.
        </div>

        <div
          style={{
            marginTop: 30
          }}
        >
          <a href="https://itunes.apple.com/de/app/Angelwetter/id1456745135"
            style={{
              marginRight: 20
            }}
            target="_blank"
          >
            <img src={itunesLogo} />
          </a>
          <a href="https://play.google.com/store/apps/details?id=com.hnm.fishingweather" target="_blank">
            <img src={googleLogo} />
          </a>
        </div>
      </div>
      <div style={{ width: 400, float: 'right' }} className="contentWrapRight">
            <div>
              <p
                style={{
                  fontSize: 40,
                  fontWeight: 500,
                  letterSpacing: '0.05em',
                  marginTop: 120,
                  paddingLeft: 22,
                  marginBottom: 20,
                }}
              >
                Funktionen
              </p>
              <ul className="functions">
                <li>moderner Angelplaner<br />mit Profi-Funktionen</li>
                <li>detaillierte Wetterdaten</li>
                <li>Mondphasen & Mondhelligkeit</li>
                <li>Solunardaten mit Aussicht<br />auf Angelerfolg</li>
                <li>Angel- & Schonzeiten<br />für Deine Region</li>
              </ul>
            </div>
      </div>
    </div>
  </Layout>
)

export default IndexPage
